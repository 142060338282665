%table {
  font-size: $sn-font-size-sm;
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  margin-bottom: $layout-spacing-normal;
  table-layout: fixed;

  * { box-sizing: border-box; }

  td {
    vertical-align: top;
    line-height: 27px; // approximate height of select dropdown

    &:empty { display: none; }
  }

  th {
    text-transform: uppercase;
    background: $sn-base-color-72;
    color: $sn-base-color--44;
  }

  // All cells
  > * > tr > * {
    padding: $sn-spacing-condensed / 2 $sn-spacing-condensed;
  }

  [name^="pay_option"] {
    margin-bottom: 0;
    width: 100%;
  }

  [alt*="thumb"] {
    display: none;
  }

  .table-text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

%table--desktop {
  @extend %table;

  th { white-space: nowrap; }

  // All cells
  > * > tr > * {
    &:first-child { padding-left: $sn-spacing-condensed * 2; }
    &:last-child { padding-right: $sn-spacing-condensed * 2; }
  }

  > tbody {
    // Regular zebra striping
    &:nth-of-type(odd) > tr:nth-child(even) { background: $sn-base-color-90; }
    &:nth-of-type(even) > tr:nth-child(odd) { background: $sn-base-color-90; }
  }
}

%table--mobile {
  @extend %table;

  tr > *:first-child {
    text-align: left;
    width: 30%;
  }
}
