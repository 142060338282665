#survey_steps {
  .receipt__order-details,
  .receipt__billing-details {
    list-style: none;
    display: table;

    li {
      display: table-row;

      > * {
        display: table-cell;

        &:first-child { padding: 6px 20px 6px 0; }
        &:last-child { padding: 6px 0 6px 0; }
      }
    }
  }

  .receipt__order-attachments {
    list-style: none;
    .icon.attachedLink {
      padding: 0;
      background-image: none;
    }
  }

  .receipt__print-link {
    @extend %pl-link;
    display: inline-block;
    margin-bottom: $layout-spacing-small;
  }

  .resultsTable {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }

  .receipt-table--desktop {
    display: none;
    @extend %table--desktop;
  }

  .receipt-table--mobile {
    display: none;
    @extend %table--mobile;
  }

  .receipt-table__column--item,
  .receipt-table__column--name { text-align: left; }

  .receipt-table__column--price { text-align: right; }

  .receipt-table__column--entry {
    text-align: left;
    width: 90px;
  }

  .receipt-table__column--total,
  .receipt-table__column--status {
    text-align: right;
    width: 90px
  }
}

.pageElement.aggregatorElement .vevent .dateImage {
  ~ .summary,
  ~ .details { margin-left: 95px; }
}

@media(max-width: 768px) {
  #survey_steps .receipt-table--mobile { display: table; }
}

@media(min-width: 769px) {
  #survey_steps .receipt-table--desktop { display: table; }
}
