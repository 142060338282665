html {
  background-color: $gray-xlight;
  height: 100vh;
}
#lightbox {
  display: none;
}
.surveyButtonWrap {
  text-align: center;
}
#survey_steps #pageContentContainer {
  background-color: #fff;
  border-bottom: 1px solid $gray-light;
  display: flex;
  align-items: stretch;
  #panelOne {
    position: relative;
    flex-shrink: 0;
    width: 18rem;
    background-color: $gray-xxlight;
    border-right: 1px solid $gray-light;
  }
  #panelTwo {
    flex-grow: 1;
  }
}
@media only screen and (max-width: 1024px) {
  #survey_steps #pageContentContainer {
    flex-direction: column;
    #panelOne {
      border: none;
      width: 100%;
    }
  }
}
