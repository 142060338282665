$input-border-radius: 2px;
$input-vertical-padding: .2857em;
$input-horizontal-padding: .75rem; // 4px / 14px
%error-message {
  margin-top: 4px;
  color: $color-alert;
}
%pl-error-field {
  &~.error-message {
    @extend %error-message;
    display: none;
  }
  &.pl-has-error {
    border-color: $color-alert;
    &:focus {
      box-shadow: 0 0 0 1px $color-alert;
    }
    &~.error-message {
      display: block;
    }
  }
}
%pl-field {
  border: 1px solid $color-content-xlight;
  border-radius: $input-border-radius;
  color: $color-content;
  font-family: $font-default;
  font-weight: $font-regular;
  padding: $input-vertical-padding $input-horizontal-padding;
  &:hover {
    border-color: $color-content-light;
  }
  &:focus {
    border-color: $color-highlight;
    box-shadow: 0 0 0 1px $color-highlight;
    outline: none;
  }
  &::placeholder {
    color: $color-content-xlight;
  }
  &::-ms-clear {
    display: none;
  }
}
@mixin pl-field() {
  @extend %pl-field;
  @extend %pl-error-field;
  @include font-small;
}
@mixin pl-field--full() {
  @extend %pl-field;
  @extend %pl-error-field;
  @include font-small;
  width: 100%;
}
@mixin pl-field--large() {
  @extend %pl-field;
  @extend %pl-error-field;
  @include font-large;
}
$select-arrow: url('#{$shared-image-path}/chevron-down.svg');
%select {
  appearance: none;
  background-image: $select-arrow, linear-gradient(#fff, $gray-xlight);
  background-position: right .5em top 50%;
  background-repeat: no-repeat, repeat-x;
  border: solid 1px $color-content-xlight;
  border-radius: $input-border-radius;
  cursor: pointer;
  outline: none;
  padding: $input-vertical-padding calc(1em + 10px) $input-vertical-padding .5em;
  font: normal 1em/1.5em $fontOne;
  &:focus:not(:active):not([disabled]) {
    border-color: $color-highlight;
    box-shadow: 0 0 0 1px $color-highlight;
  }
  &:hover:not([disabled]) {
    background-image: $select-arrow, linear-gradient($gray-xlight, #fff);
    border-color: $color-content-light;
  }
  &[disabled] {
    background-color: #fff;
    background-image: $select-arrow;
    border-color: $color-content-xxlight;
    color: $color-content-xxlight;
    cursor: default;
  }
  // Removes blue highlight on focused selects in IE 10 & 11
  &:focus::-ms-value {
    background-color: transparent;
    color: black;
  }
  // Hides default select arrow in IE 10 & 11
  &::-ms-expand {
    display: none;
  }
  &.pl-has-error {
    border-color: $color-alert;
    &:focus {
      box-shadow: 0 0 0 1px $color-alert;
    }
  }
}
%field__label {
  color: $color-content-light;
  display: block;
  padding-bottom: .2857em; // 4px / 14px
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

.error-message--persistent {
  @extend %error-message;
}

// This is using HQ styles and should be replaced.
textarea {
  background: white;
  border-radius: $pad/2;
  border: 1px solid darken($lt1, 5%);
  padding: $pad $pad*2;
  margin: 0 0 $pad/2;
  font-size: 12px;
  resize: none;
  &.error, .field_with_errors & {
    border: 1px solid $error;
    box-shadow: $error-shadow;
    outline: none;
    &~.error-message {
      display: block;
      color: $error;
    }
  }
  ~.error-message {
    display: none;
  }
  &:focus, &:focus.error, .field_with_errors:focus & {
    border: 1px solid $notify;
    box-shadow: $focus-shadow;
    outline: none;
  }
  &~label {
    display: block;
    color: $dk4;
    font-size: 12px;
    font-style: italic;
    font-weight: $light;
    margin: 0;
  }
  &[disabled], &.disabled {
    box-shadow: none;
    opacity: 0.5;
  }
}

%appearance {
  appearance: none;
  border: 0px;
  box-shadow: none;
  background: transparent;
  color: #fff;
}
#survey_steps {
  .siteContainer {
    .formElement {
      padding: $layout-spacing-small $layout-spacing-medium;
      .choiceLabel,
      .settingValues input[type="radio"] {
        padding-bottom: 20px;
        padding-left: 10px;
        line-height: 1.5em;
      }
      &.hiddenFormElement {
        max-width: 50%;
        border-radius: 2px;
        background-color: #f2f2f3;
        border: solid 1px #babbc1;
        border-style: dashed;
        border-width: 1px;
        > input {
          width: 100%;
        }
      }
      &.date > select {
          width: auto;
      }
    }
    input[type="checkbox"] {
      width: auto !important;
      border: 0px;
      top: 5px;
      position: relative;
      &:hover {
        border-color: transparent;
      }
      &:focus {
        border-color: transparent;
        box-shadow: none;
        outline: none;
      }
    }
    input[type="radio"] {
      position: relative;
      @extend %appearance;
      width: 1rem;
      height: 1rem;
      margin: auto;
      margin-right: 10px;
      top: 6px;
      &:checked, &:focus, &:active {
        @extend %appearance;
      }
      &:checked {
        @extend %appearance;
        &:before {
          background-position: center;
          background-repeat: no-repeat;
          border-color: #13769c;
          background-image: url('data:image/svg+xml;utf-8,<svg width="8" height="8" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><circle cx="4" cy="4" r="4" fill="#13769C"/></svg>');
        }
      }
      &:disabled:before {
        border-color: #ddd;
      }
      &:before {
        content: '';
        display: inline-block;
        background-color: #fff;
        border: 1px solid #505c63;
        flex-shrink: 0;
        height: 1rem;
        margin-right: .5714rem;
        vertical-align: -12%;
        width: 1rem;
        position: absolute;
        z-index: 2;
        border-radius: 50%;
        left: 0px;
        top: 0px;
      }
    }
    label, input, textarea {
      width: 100%;
    }
    select {
      @extend %select;
      @extend %pl-error-field;
      margin-bottom: 10px;
      width: 100%;
      text-overflow: ellipsis;
    }
    label {
      @extend %field__label;
    }
    input[type="text"], input[type="number"], input[type="date"],
    input[type="password"], input[type="email"], input[type="search"],
    input[type="tel"], input[type="url"], input[type="time"], textarea {
      @include pl-field();
    }
    form {
      margin: auto;
      clear: both;
      br {
        display: none;
      }
      label {
        @extend %field__label;
      }
      input[type="text"], input[type="number"], input[type="date"],
      input[type="password"], input[type="email"], input[type="search"],
      input[type="tel"], input[type="url"], input[type="time"] {
        @include pl-field();
      }
    }
    [team-roster-question] td {
      text-align: center;
    }
  }
}
@media only screen and (max-width: 768px) {
  #survey_steps .siteContainer {
    label, input, textarea {
      width: 100%;
    }
    .formElement {
      &.hiddenFormElement {
        max-width: 100%;
      }
    }
  }
}
@mixin appearance-reset($app) {
  appearance: $app !important;
}
@mixin appearance-radio-reset($app) {
  input[type="#{$app}"] {
    @include appearance-reset($app);
    &:checked, &:focus, &:active {
      @include appearance-reset($app);
    }
  }
}

/* IE & Edge Form Support */

#survey_steps {
  /* JS Browser Check & Add Class Support for All Edge Versions */
  &.sn-edge .siteContainer {
    @include appearance-radio-reset(radio);
  }
  .siteContainer {
    /* IE10+ CSS styles go here */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @include appearance-radio-reset(radio);
    }
    /* Edge 13+ CSS styles go here */
    @supports (-ms-accelerator:true) {
      @include appearance-radio-reset(radio);
    }
  }
}
input:not([type="button"]).number,
input:not([type="button"]).mini,
input:not([type="button"]).tiny {
  width: 100%;
  max-width: 80px;
}
.shortText {
  input[type="text"].small {
    max-width: 192px;
  }
  input[type="text"].medium {
    max-width: 384px;
  }
  input[type="text"].large {
    max-width: 768px;
  }
}
#login-options {
  border-radius: 4px;
}
.login-options-twoOptions .login-option {
  float: left;
  padding: 5px 1%;
  width: 48%;
  p {
    margin-right: 20px
  }
}
.login-options-threeOptions .login-option {
  float: left;
  padding: 5px 1%;
  width: 32%;
}
#login-options .login-options-threeOptions .login-option p {
  margin-right: 25px;
}
.login-options-threeOptions .login-options-body .login-option {
  min-height: 131px;
}
.login-options-threeOptions .login-option-continue {
  width: 28%;
}
#login-options .login-options-threeOptions .login-option-continue p {
  margin-right: 0;
}

.pl-select-group {
  display: flex;
  flex-wrap: nowrap;

  > *:not(:first-child) {
    margin-left: $layout-spacing-small;
  }
}
