/* Sub Nav Universal Style */

.subNav li, .relatedSections li, .siteUserMenu li {
  list-style-type: none;
  display: block;
  padding: 10px;
}
.subNav a {
  display: block;
  text-decoration: none;
}
.subNav h4, .relatedSections h4 {
  display: inline-block;
  width: 100%;
  color: #bcbcbc;
  vertical-align: top;
  padding: 10px 20px 0px 20px;
  font: 100 14px/1.5em $font-default, sans-serif;
  margin-bottom: 10px;
}
.user_mode {
  .subNav li, .relatedSections li {
    padding: 0 10px;
  }
}

/* Site User Menu */

.siteUserMenu {
  background: #666;
  font-size: 11px;
  h4 {
    color: #fff;
    padding-left: 60px;
    height: 26px;
    line-height: 30px;
    font-weight: bold;
    font-size: 12px;
  }
  a, a:hover {
    display: block;
    padding: 5px;
    color: #fff;
  }
}
