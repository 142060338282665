#survey_steps {
  #displayBodyHeader {
    font-size: $font-xlarge;
    line-height: 1.3333;
    border-bottom: 1px solid $gray-light;
    padding: $layout-spacing-large;
    margin: 0;
  }
  .vevent {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .dateImage {
    width: 75px;
    float: left;
    margin-right: 10px;
    list-style-type: none;
    text-align: center;
    .month {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      background-color: $color-highlight;
      color: #fff;
    }
    .date {
      font-size: 48px;
      line-height: 1;
      background-color: #eee;
      font-weight: 700;
    }
  }
  .summary {
    font-size: 18px;
  }
  .status {
    font-weight: bold;
  }
  .details {
    list-style: none;
  }
  .description {
    font-size: 12px;
  }
  .surveyButtonWrap {
    background: transparent;
    border: 0px;
    border-top: 1px solid #ddd;
    margin: 50px 0px 0px 0px;
    padding: 15px 25px;
    border-radius: 0px;
    position: relative;
    clear: both;
    input {
      width: auto !important;
      cursor: pointer;
    }
  }
  #surveyStatus a {
    display: block;
    margin: auto;
    width: 100%;
    padding: 5px;
    &.goToLink:before {
      position: relative;
      padding-right: 8px;
      color: #666;
    }
  }
  form .pageElement {
    padding: 0;
  }
  .pageEl {
    margin-bottom: 1em;
  }
  .pageElement.textBlockElement {
    padding: 15px;
  }
  .siteContainer {
    #who_are_you_registering_page .textBlockElement>h3 {
      padding: 0;
    }
    #PersonaSelect, .miniPersona {
      max-width: $max-width;
      width: 100%;
    }
  }
  #form_div .layoutContainer:last-child {
    content: 'last child';
    display: block;
  }
  .reg-login {
    &.surveyButtonWrap {
      margin: 15px;
      max-width: 344px;
      width: 100%;
      padding: 25px;
      margin-left: auto;
      margin-right: auto;
      border: 1px solid $gray-light;
    }
    p {
      color: rgba(35, 40, 43, 0.7);
    }
  }
}
.sn-media-slider .slider {
  .slide-title {
    font: normal 2em/1.5em $font-default;
    margin: 1% 0;
  }
  .slide-description {
    font: normal 1em/1.5em $font-default;
    margin: 1% 0;
  }
}
%icon-link {
  display: inline-block;
  content: '';
  width: 20px;
  height: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.60;
}
.icon {
  &.editLink, &.deleteLink {
    background-image: none;
    padding-left: 5px;
    font: normal 14px/1em $font-default;
    &:before {
      @extend %icon-link;
    }
    &:hover {
      color: $color-highlight !important;
    }
  }
  &.editLink:before {
    background-image: url('../images/se-ui/system-sprite/pencil.svg');
  }
  &.deleteLink:before {
    background-image: url('../images/se-ui/system-sprite/times-circle.svg');
  }
}
.secureCheckoutMessage {
  margin-bottom: 1.5rem;
}

.displayNone { display: none; }

@media only screen and (max-width: 1024px) {
  #survey_steps {
    .adminToggle, #adminNav, #accountNav {
      display: none;
    }
  }
}
@media only screen and (max-width: 768px) {
  #survey_steps {
    #tstRegistrationHeader h1 {
      font-size: 20px;
    }
    #tstRegistrationHeader h2 {
      font-size: 18px;
    }
    #surveyNameHeader h1 {
      font-size: 16px;
    }
    .reg-login {
      &.surveyButtonWrap {
        margin: 30px;
        max-width: calc(100% - 60px);
        .reg-login_body {
          width: 75%;
          margin: auto;
        }
      }
    }
  }
}
