/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
  }
  .cutline {
    margin: 0;
    width: 100%;
    background: transparent;
    font-style: normal;
    font: normal 16px/1.3em $fontOne;
    color: #666;
    padding: 15px 15px 15px 0px;
  }
}
