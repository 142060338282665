$color-disabled: #bdbec4;
#sequenceMenu .subNav ul.steps {
  padding: $layout-spacing-medium 0;
  margin: 0;

  li {
    padding: 0;

    a, span {
      color: $color-disabled;
      display: block;
      position: relative;
      padding: $layout-spacing-small $layout-spacing-xlarge $layout-spacing-small $layout-spacing-large;
    }

    &.complete {
      a { color: $color-highlight; }

      a, span {
        &:before {
          border: $option-border-size solid $color-highlight;
          border-radius: 2px;
          content: '';
          display: inline-block;
          height: $option-input-size;
          top: 50%;
          right: $layout-spacing-large;
          margin-top: -$option-input-size / 2;
          position: absolute;
          width: $option-input-size;
          background-color: $color-highlight;
          background-image: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 11 8" xmlns="http://www.w3.org/2000/svg"><path d="M9.6.293S4.626 5.52 4.512 5.405L2.1 2.993.793 4.3 4.5 8.007 10.907 1.6 9.6.293z" fill="#fff"/></svg>');
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }

    &.selected {
      background-color: $color-content-xxxlight;
      box-shadow: inset 4px 0 0 0 $color-highlight;

      a, span { color: $gray; }
    }
  }
}

.sub-nav-toggle { display: none; }

@media only screen and (max-width: 1024px) {
  #sequenceMenu {
    $animation-speed: 200ms;

    .subNav {
      position: absolute;
      background-color: $gray-xxlight;
      overflow: hidden;
      max-height: 0;
      transition: max-height $animation-speed;
      width: 100%;
      z-index: 3;
    }

    .sub-nav-toggle {
      display: block;
      padding: $layout-spacing-large $layout-spacing-xlarge $layout-spacing-small $layout-spacing-large;
      text-align: center;
      background-color: #fff;
      border-bottom: 1px solid $gray-light;
    }

    .sub-nav-toggle-text {
      color: $color-highlight;
      position: relative;
      cursor: pointer;
      font-size: 14px;
      padding-right: 20px;

      &:after {
        $icon-size: 16px;
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -$icon-size / 2;
        width: $icon-size;
        height: $icon-size;
        background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16"><g><path fill="#13769c" d="M8,12.2 L14,6.152 L12.8571429,5 L8,9.896 L3.14285714,5 L2,6.152 L8,12.2 L8,12.2 Z"/></g></svg>');
        transition: transform $animation-speed;
      }
    }

    &.sub-nav-toggle-open {
      .subNav {
        box-shadow: 0 0 0 1px #d4d9dc, 0 2px 5px 1px rgba(0, 0, 0, .2);
        max-height: 30rem;
        overflow-y: scroll;
        overflow-x: hidden;
      }

      .sub-nav-toggle-text:after { transform: rotate(180deg); }
    }
  }
}
