$orange-color-insurance-header: rgb(255, 153, 45);
.summary-banner {
  &__container {
    border: 1px solid $color-ncsa-gray;
    border-radius: 5px;
    padding: 0 !important;
    margin-left: $layout-spacing-small * 2;
    max-width: 700px;
  }
  &__inner-wrap {
    border-left: 1rem solid $color-ncsa-yellow;
    border-radius: 4px;
    margin: 0;
    padding: 1rem;
    .pl-media--text-centered {
      margin-top: 1em;
    }
    .pl-option--descriptive {
      .pl-option__label {
        &::before {
          height: 1rem;
          width: 1rem;
        }
      }
      .pl-link {
        color: $color-highlight !important;
      }
    }
    .pl-heading--xxsmall.pl-option__help-text {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: $layout-spacing-medium;
    }
    .sports-container {
      margin-bottom: 0;
      .ncsa-sports {
        font-weight: 400;
        line-height: 1.14;
        font-size: 1rem;
        margin-bottom: 5px;
        background-image: url(../../assets/latest/images/chevron-down.svg),linear-gradient(#fff,#f2f3f4);
        background-position: right .5em top 50%;
        background-repeat: no-repeat,repeat-x;
        border: solid 1px rgba(35,40,43,.3);
        border-radius: 2px;
        cursor: pointer;
        outline: 0;
        padding: .2857rem calc(1em + 10px) .2857rem .5em;
      }
      .ncsa-s {
        padding-top: $layout-spacing-large;
        position: relative;
        left: 6em;
        display: block;
        blockquote.orange-text {
          font-size : $button-padding-horizontal;
          font-family: Georgia, Times, "Times New Roman", serif;
          width : 450px;
          padding : 4px 0px 4px 40px;
          line-height : 1.45;
          position : relative;
          color : $orange-color-insurance-header;
          border : none;
        }

        blockquote.orange-text::before {
          display : block;
          content : "“";
          font-size : $table-row-min-height;
          position : absolute;
          left : 10px !important;
          top : -8px !important;
          color : $orange-color-insurance-header;
        }

        blockquote.orange-text::after {
          content : " ";
          display : inline-block;
          font-weight : 900;
          color : $orange-color-insurance-header;
        }

        blockquote.orange-text ul {
          color : $orange-color-insurance-header;
          font-size : $button-padding-horizontal;
          display : block;
          margin-top : 5px;
          padding-left : 15px;
          list-style-type : square;
        }
      }
    }
    .ncsa-error {
      margin-top: 0;
    }
  }
}

.registration-dialog {
  .pl-modal__header {
    border-bottom: none;
  }
  .pl-modal__content {
    padding-top: $layout-spacing-small;
  }
}
