#survey_steps {
  .pageElement {
    box-sizing: border-box;
    padding: $layout-spacing-normal;
  }

  .cart-table--desktop {
    display: none;
    @extend %table--desktop;
  }

  .cart-table--mobile {
    display: none;
    @extend %table--mobile;
  }

  .cart-table__payment-options {
    list-style: none;
    line-height: initial;

    & > li > ul {
      margin-top: 4px;
      list-style: none;
    }
  }

  .cart-table__total {
    text-transform: uppercase;
    font-weight: $font-semibold;
  }

  .cart-table__column--quantity,
  .cart-table__column--total,
  .cart-table__column--due-now { width: 95px; }

  .cart-discount-code {
    #discount_code { width: initial; }
    .buttonLink { margin-left: $layout-spacing-small; }
  }

  .cart-table__cell--savings,
  .cart-table__cell--savings-label {
    font-weight: $font-semibold;
    background-color: $color-highlight-light;
  }

  .cart-table__cell--savings {
    border-top: 1px solid $color-highlight-dark;
    border-bottom: 1px solid $color-highlight-dark;
    border-right: 1px solid $color-highlight-dark;
  }

  .cart-table__cell--savings-label {
    border-top: 1px solid $color-highlight-dark;
    border-left: 1px solid $color-highlight-dark;
    border-bottom: 1px solid $color-highlight-dark;
  }

  .reg-cart-discount-code {
    #discount_code { width: auto; }

    .buttonLink {
      margin-left: $layout-spacing-small;
    }
  }
}

@media(max-width: 768px) {
  #survey_steps .cart-table--mobile { display: table; }
}

@media(min-width: 769px) {
  #survey_steps .cart-table--desktop { display: table; }
}
