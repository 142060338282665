#adminNav {
  position: absolute;
  right: 0;
  top: 60px;
  right: $layout-spacing-large;
  ul {
    list-style: none;
    >li {
      display: inline-block;
      >a {
        @extend %ngin-reg-button;
        cursor: pointer;
      }
      &.selected a {
        background-color: $color-highlight;
        background-image: linear-gradient($color-highlight, $color-highlight-dark);
        border-color: $color-highlight-dark;
        color: #fff;
        &:hover {
          border-color: $color-highlight-dark !important;
          background-color: $color-highlight !important;
          background-image: none !important;
        }
      }
    }
  }
}
