#survey_steps {
  font-family: $font-default;
  padding: 0;
  margin: 0;
  #pageContentContainer a, #tstRegistrationHeader .adminToggle {
    @extend %pl-link;
    font-weight: $font-regular;
  }
  .siteContainer {
    max-width: 100%;
    width: 100%;
    display: block;
    margin: auto;
  }
  .textBlockElement {
    .miniPersona+& {
      margin-top: 1em;
    }
    .pageElement .resultsTable {
      margin: .5em 0 1.5em 0;
      width: 100%;
      tr {
        border: none;
        border: 1px solid #CCC;
      }
      th, td {
        background: none;
        color: #333;
        border: none;
        text-align: left;
        padding: .45em .75em;
      }
      th {
        width: 35%;
        border-right: 1px solid #CCC;
      }
    }
  }
  #siteFooter {
    max-width: 100%;
    padding: 15px;
    text-align: center;
    display: none;
    a {
      color: #fff;
      text-decoration: none;
      margin-right: 10px;
      opacity: 0.9;
      font: 100 .9em/1.5em $font-default;
    }
    a:hover {
      opacity: 1;
    }
  }
}
