#tstRegistrationHeader {
  display: flex;
  align-items: center;
  position: relative;
  background: $gray-xlight;
  border-bottom: 1px solid $gray-light;
  padding: $layout-spacing-large;
  .registration-header-logo {
    display: inline-block;
    padding-right: $layout-spacing-large;
    img {
      display: block;
      max-height: 65px;
      max-width: 65px;
      margin: auto;
      width: 100%;
    }
  }
  .registration-header-text {
    display: inline-block;
    >* {
      padding: 0;
      margin: 0;
    }
  }
  .registration-header-survey-name {
    font: normal 24px/1.5em $font-default;
  }
  .registration-header-site-name {
    font: normal 18px/1.5em $font-default;
  }
  a.adminToggle {
    background: transparent;
    width: 100%;
    position: absolute;
    right: 0;
    top: $layout-spacing-large;
    right: $layout-spacing-large;
    text-align: right;
    &:before {
      content: '\f053';
      display: inline-block;
      font-family: FontAwesome;
      margin-right: 10px;
    }
  }
}
@media only screen and (max-width: 1024px) {
  #tstRegistrationHeader {
    justify-content: center;
    .registration-header-logo {
      width: initial;
    }
  }
}
