.reg-membership-card {

  $reg-membership-card-min-height: 13rem; // just for looks
  $reg-membership-card-padding: $layout-spacing-large;

  background: $bg-default;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,.12), 0 2px 2px 0 rgba(0,0,0,.24);
  min-height: $reg-membership-card-min-height;
  overflow: hidden;
  padding: $reg-membership-card-padding;

  &__org {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: $reg-membership-card-padding;
  }

  &__org-avatar {
    flex: 0 0 auto;

    // fixes spacing issues related to line-height
    > * {
      display: block;
    }
  }

  &__org-name {
    color: $color-content;
    flex: 0 1 auto;
    font-size: $se-font-size-500;
    font-weight: $font-bold;
    padding: 8px;
  }

  &__membership_name {
    color: $color-content;
    font-size: $se-font-size-750;
    font-family: $font-default;
    font-weight: $font-semibold;
    line-height: $font-leading-subheading;
  }

  &__membership_details {
    color: $color-content-light;
    font-size: $se-font-size-500;
    font-weight: $font-regular;
    line-height: $font-leading-body;
  }
}
