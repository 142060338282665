// Styles to mimic parts of the container card from the CL

.reg-container-card-layout {

  $reg-container-card-layout-spacing: $layout-spacing-large;
  $reg-container-card-layout-max-width: 960px;
  $reg-sidebar-width: 252px;

  @media (min-width: $media-tablet) {
    padding: $reg-container-card-layout-spacing;

    .reg-container-card ~ .reg-container-card {
      margin-top: $reg-container-card-layout-spacing;
    }
  }

  @media (min-width: #{$reg-container-card-layout-max-width + $reg-sidebar-width}) {
    padding: $reg-container-card-layout-spacing calc(50% - #{($reg-container-card-layout-max-width / 2) - $reg-container-card-layout-spacing)});
  }

  // override spacing from default reg styles
  #survey_steps .siteContainer & select {
    margin-bottom: 0;
  }
}

.reg-container-card {

  $reg-container-card-border-radius: 2px;
  $reg-container-card-border-mobile: 1px solid $gray-light;
  $reg-container-card-margin: $layout-spacing-small;
  $reg-container-card-padding: $layout-spacing-large;
  $reg-container-card-content-border: 2px solid $color-highlight-dark;
  $reg-container-card-shadow: 0 0 2px 0 rgba(0, 0, 0, .12), 0 2px 2px 0 rgba(0, 0, 0, .24);
  $reg-container-card-popover-arrow-size: 17px;

  background: $bg-default;
  padding: $reg-container-card-padding / 2;

  @media (max-width: $media-tablet - 1) {
    & ~ & {
      border-top: $reg-container-card-border-mobile;
    }
  }

  @media (min-width: $media-tablet) {
    border-radius: $reg-container-card-border-radius;
    box-shadow: $reg-container-card-shadow;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: ($reg-container-card-padding / 2) ($reg-container-card-padding / 2) 0;

    @media (min-width: $media-tablet) {
      flex-wrap: nowrap;
    }
  }

  &__header-main {
    flex: 100 100 100%;
    padding-bottom: ($reg-container-card-padding / 2);
  }

  &__header-actions {
    flex: 1 1 auto;
    padding: 0 0 ($reg-container-card-padding / 2);

    @media (min-width: $media-tablet) {
      padding-left: $reg-container-card-padding
    }
  }

  &__title {
    color: $color-content;
    font-size: $se-font-size-700;
    font-weight: $font-semibold;
    line-height: $font-leading-body;
  }

  &__description {
    color: $color-content-light;
    font-size: $se-font-size-500;
    font-weight: $font-regular;
    line-height: $font-leading-subheading;
    margin-top: $reg-container-card-margin;
  }

  &__popover-trigger {
    align-items: center;
    background: none;
    border: 0;
    border-bottom: 1px dashed $color-gray-20;
    color: $color-content-light;
    display: inline-flex;
    flex-direction: column-reverse;
    font-size: $se-font-size-500;
    font-weight: $font-regular;
    line-height: $font-leading-subheading;
    margin: 0;
    outline: none;
    padding: 0;
    white-space: nowrap;
  }

  &__popover {
    background: $color-highlight-light;
    border: 1px solid $color-highlight;
    border-radius: 3px;
    color: $color-content;
    display: none;
    font-size: $se-font-size-500;
    justify-content: flex-start;
    margin-right: 8px; // adds a bit of space between the edge of the window
    max-width: 300px;
    min-width: 170px;
    overflow: unset;
    padding: 12px;
    position: absolute;
    left: -999in;
    text-align: left;
    text-overflow: unset;
    top: -999in;
    white-space: normal;
    z-index: 100;

    &::after {
      background: $color-highlight-light;
      border-right: 1px solid $color-highlight;
      border-bottom: 1px solid $color-highlight;
      top: calc(100% - #{$reg-container-card-popover-arrow-size / 2});
      content: '';
      height: $reg-container-card-popover-arrow-size;
      left: calc(50% - #{$reg-container-card-popover-arrow-size / 2});
      position: absolute;
      transform: rotate(36deg) skew(-20deg);
      width: $reg-container-card-popover-arrow-size;
    }

    &--flipped {
      &::after {
        top: auto;
        bottom: calc(100% - #{$reg-container-card-popover-arrow-size / 2});
        transform: rotate(216deg) skew(-20deg);
      }
    }
  }

  &__popover-trigger:focus {
    pointer-events: none;
  }

  &__popover-trigger:focus + &__popover {
    display: block;
  }

  &__content {
    border-top: $reg-container-card-content-border;
    color: $color-content;
    font-size: $se-font-size-500;
    font-weight: $font-regular;
    line-height: $font-leading-body;
    padding: $reg-container-card-padding ($reg-container-card-padding / 2) ($reg-container-card-padding / 2);
  }

}
