/* Typgography */

// 1. Base
// 2. Headers
// 3. Lists
// 4. Inline mods
// 5. Block mods
// 6. Anchors
// 7. Tables
// 8. Sibling rules
// 9. Removal/Relocation
// 1. Base
html, body {
  font-family: $fontTwo;
  font-size: ($base-font-size) * 1px;
  line-height: $base-line-height;
  font-weight: $base-font-weight;
}
body {
  font-size: 100%;
  text-rendering: optimizeLegibility; // for WebKit. FF is on by default. IE? probably doesn't support it.
}
p {
  font-size: 1em;
  margin-bottom: 1rem;
}
// 2. Headers
h1, h2, h3, h4, h5, h6 {
  font-family: $fontOne;
  font-weight: 700;
  line-height: 1.125;
}
h1 {
  font-size: em(48);
}
h2 {
  font-size: em(32);
}
h3 {
  font-size: em(24);
  text-transform: uppercase;
}
h4 {
  font-size: em(18);
  text-transform: uppercase;
}
h5 {
  font-size: em(16);
  text-transform: uppercase;
}
h6 {
  font-size: em(16);
}
// 3. Lists
ul {
  list-style-type: disc;
}
.settingValues li{
  list-style: none;
}
@mixin list-unstyled {
  margin-left: 0;
  list-style: none;
}
// 4. Inline mods
b, strong {
  font-weight: bold;
}
i, em {
  font-style: italic;
}
small {
  font-size: .75em;
}
mark {
  background-color: yellow;
}
s, strike, del {
  text-decoration: line-through;
}
u {
  text-decoration: underline;
}
code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}
// 5. Block mods
blockquote {
  font-family: $fontOne;
  font-style: italic;
  font-weight: 600;
  font-size: em(27);
  line-height: 1.3333333333;
  float: right;
  color: $link-color;
  width: 50%;
  padding: 0 0 1em 1em;
  &:before {
    content: "“";
    display: inline-block;
    font-weight: 900;
    color: #CCC;
  }
  &:after {
    content: "”";
    display: inline-block;
    font-weight: 900;
    color: #CCC;
  }
  p {
    display: inline;
  } // Revisit when liquid
}
code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
pre {
  display: block;
  padding: $base-padding-sm;
  margin: 0 0 1rem 0;
  font-size: 90%;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap !important;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
hr {
  @include line-pattern(#AAA, transparent);
  height: 3px;
  margin: 1rem 0;
  border: none;
  &.hrElement {
    margin: 1rem 0;
  }
}
// 6. Anchors
a {
  color: $link-color;
  text-decoration: none;
  transition: color $transition-standard;
  &:hover {
    color: $link-color-darken-10;
  }
}
.pageElement a {
  font-weight: 700;
}
// 7. Tables
table {
  font-size: em(12);
}
// 8. Sibling rules
.text, .previewText, .newsText {
  > {
    p, h1, h2, h3, h4, h5, h6, ol, ul, blockquote, pre {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ul, ol {
    margin-left: 1.75em;
    margin-bottom: 1em;
    li {
      >ul, >ol {
        margin-bottom: 0;
      }
    }
  }
}

/* -------------------------------------------------------------------- */

// 9. Removal/Relocation
// These should be removed from _typography and added to their appropriate locations
.toolIcon {
  background-repeat: no-repeat;
  padding: 5px 0 15px 40px;
}
p.tags {
  font-size: .7em;
}
.ellipsis {
  /* Needs a fixed width to work */
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// # Typography
// Default styles for headings, body copy, lists, etc.
// ## Spacing between elements
// @include mixin in external stylesheets that reference this one
@mixin content-spacing {
  margin-bottom: .8571em; // 12px / 14px

  &:last-child {
    margin-bottom: 0;
  }
}
// @extend placeholder in this theme
%content-spacing {
  @include content-spacing;
}
// ## Headings
%heading {
  font-weight: $font-regular;
  margin: 1em 0 .25em;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}
h6, .pl-heading--xxsmall {
  @extend %heading;
  @include font-xsmall;
  color: $color-content-light;
}
h5, .pl-heading--xsmall {
  @extend %heading;
  @include font-small;
  color: $color-content-light;
}
h4, .pl-heading--small {
  @extend %heading;
  @include font-medium;
  color: $color-content-light;
}
h3, .pl-heading--medium {
  @extend %heading;
  @include font-medium;
}
h2, .pl-heading--large {
  @extend %heading;
  @include font-large;
}
h1, .pl-heading--xlarge {
  @extend %heading;
  @include font-xlarge;
}
.pl-heading--xxlarge {
  @extend %heading;
  @include font-xxxlarge;
}
// ## Paragraphs
p {
  @extend %content-spacing;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}
// ## Formatting
blockquote {
  @extend %content-spacing;
  border-left: solid .2857rem $color-content-xxlight; // 4px / 14px
  color: $color-content-light;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
small {
  font-size: $font-xsmall;
}
mark {
  background-color: $color-highlight-light;
}
code, kbd, samp, pre {
  background-color: $color-content-xxxlight;
  padding: .1429rem .2857rem; // 2px / 14px, 4px / 14px
}
pre {
  padding: .8571rem; // 12px / 14px
}
// ## Images
// Should probably be moved outside of this partial
img {
  max-width: 100%;
}
// ## Lists
ol, ul {
  margin: 0;
  padding-left: 0;
}
.pl-list {
  @include content-spacing;
  padding-left: 1.5em;
  li {
    margin-top: .2857em; // 4px / 14px
  }
}
ul.pl-list {
  list-style: disc;
}
ol.pl-list {
  list-style: decimal;
}
// ## Horizontal rule
hr {
  border: 0;
  border-top: solid 1px $color-content-xxlight;
  margin-bottom: 1.4286rem; // 20px / 14px
  margin-top: 1.4286rem; // 20px / 14px
}
// ## Alignment classes
.pl-text--left {
  text-align: left;
}
.pl-text--right {
  text-align: right;
}
.pl-text--center {
  text-align: center;
}
// ## Color classes
@each $label, $color in (highlight: $color-highlight, alert: $color-alert, warn: $color-warn, success: $color-success) {
  .pl-color--#{$label} {
    color: $color !important;
    fill: $color !important;
  }
}
// ## Lead (a.k.a lede)
.pl-lead {
  @include font-large;
}
// ## Link
// Text link styles
%pl-link, .pl-link {
  cursor: pointer;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}
.pl-link--file-input {
  @extend %pl-link;
  input[type='file'] {
    left: -999in;
    position: absolute;
  }
}
// ## CSS Multi-Column Control
@mixin pl-column-break-before {
  -webkit-column-break-before: always; // autoprefixer gets this wrong
  break-before: column;
}
@mixin pl-column-break-after {
  -webkit-column-break-after: always; // autoprefixer gets this wrong
  break-after: column;
}
@mixin pl-column-break-none {
  break-inside: avoid;
}
.pl-column-break-before {
  @include pl-column-break-before;
}
.pl-column-break-after {
  @include pl-column-break-after;
}
.pl-column-break-none {
  @include pl-column-break-none;
}
.pl-ellipsis {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pl-multi-line-text {
  hyphens: auto;
  white-space: pre-line;
  word-break: break-word;
}
