/* Form Error Messages
-------------------------------------------------------------------- */

.formError.formElement h4 {
  font: normal 12px/1.5em $fontOne;
  background-repeat: no-repeat;
  padding: 15px;
  background-color: $color-alert;
  color: #fff;
  border-radius: 2px;
  overflow: visible;
  text-align: left;
  display: inline-block;
  position: relative;
  margin-bottom: 1.5em;
  &:after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $color-alert;
    bottom: -6px;
    left: 8%;
  }
}

/* No Data has been previously entered
-------------------------------------------------------------------- */

.noEntry {
  color: #666;
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  line-height: 120%;
  padding: 10px;
  border-top: solid 1px #dcdcdc;
  border-bottom: solid 1px #dcdcdc;
  background-color: #fbfbfb;
  font-style: normal;
  font-weight: normal;
  margin: auto;
  &.pageElement {
    float: none;
  }
}
.rapidContentConstruction .noEntry, .constructionLightbox .noEntry {
  color: #DDA301;
  background: #FFF3B0;
  border-top: solid 1px #DDA301;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

/* User Actions Pane
-------------------------------------------------------------------- */

.userActionsPane {
  margin-top: 15px;
  width: 100%;
  list-style-type: none;
  line-height: 140%;
}
.userActionsPane li {
  background-color: #FFF6C6;
  background-image: url($asset_path_for+"/app_images/flashBack.jpg");
  background-repeat: repeat-x;
  border: 1px solid #FDE045;
  border-top: none;
  display: block;
  line-height: 120%;
  padding: 10px;
  margin: 0 0 15px;
}
.userActionsPane h5 {
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 7px;
}
.userActionsPane p {
  margin-bottom: 10px;
}

/* Loading Animation
-------------------------------------------------------------------- */

.loadingAnimation {
  font-weight: bold;
  font-style: italic;
}

/* Maintenance Message
-------------------------------------------------------------------- */

#maintenanceMessage {
  background: #fde045 url($asset_path_for+"/app_images/constructionBG.gif") 50% 50% repeat;
  background-image: linear-gradient(to bottom, rgba(252, 205, 34, 0) 0%, rgba(252, 205, 34, 0.5) 100%), url($asset_path_for+"/app_images/constructionBG.png");
  color: #333;
  padding: 15px;
  position: absolute;
  left: 35%;
  right: 35%;
  top: 50px; // not sure if this is supposed to match $ngin-bar-height, so I'm going to leave it at 50px for now
  text-align: left;
  z-index: 999;
  box-shadow: 0 10px 50px #000;
  border-radius: 10px;
}
#maintenanceMessage div {
  background: #f2f2f2;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}
#maintenanceMessage h2 {
  border-bottom: 1px solid #e5e5e5;
  padding: 0 0 10px;
  text-shadow: 0 1px 1px #fff;
}
#maintenanceMessage p {
  border-top: 1px solid #fff;
  padding: 5px 0 15px;
}

/* Tool Settings Success/Error message
-------------------------------------------------------------------- */

.form-notice {
  margin: 0 5px;
}
