@charset "UTF-8";
@import "variables";
@import "../../platform/styles/variables/layout";
@import "../../platform/styles/variables/fonts";
@import "../../platform/styles/variables/colors";

.registration-insurance {
  width: 100%;
  border: 1px solid $body-copy-color-one;
  p, label {
    font-weight: $font-semibold;
    color: $body-copy-color-two;
  }
  &__header {
    background: $black-color-insurance-header;
    padding-top: 2px;
    &--banner.pl-heading--xxsmall:last-child {
      color: $table-body-background;
      margin: 5px 0;
      text-transform: uppercase;
    }
  }
  &__body {
    background: $order-insurance-body-color;
    border: solid 1px $order-insurance-border-color;
    width: 100%;
    &__wrapper {
      padding-right: $layout-spacing-medium;
      &__info {
        padding-top: $button-padding-vertical;
        padding-bottom: $table-header-padding-horizontal*2;
        .disclaimer {
          font-size: $font-xxsmall;
          font-weight: $font-semibold;
          font-style: italic;
          line-height: 1.2;
          text-align: left;
          margin-top: 0;
          &__link {
            color: $blue-color-variant-two !important;
          }
        }
      }
    }
    &--choice {
      border-radius: $borderWidth;
      background-color: #f0faf4;
      box-shadow: $order-insurance-box-shadow;
      padding: $order-insurance-padding;

      .pl-option--descriptive {
        padding-top: 0.75em;
        .pl-option__label {
          margin: 0;
          color: $gray-xdark;
          padding-bottom: 1em;
        }
      }
      .pl-option--descriptive.disclaimer {
        padding: 0 0 0.75em 3.75em;
        a {
          color: $link-color-lighten-10 !important;
        }
        p {
          font-weight: normal;
        }
        .agree-condition {
          padding-top: 1em;
        }
      }
      .option-container {
        .pl-option--descriptive {
          margin: 0;
          padding-top: 0;
        }
        :last-child {
          padding-bottom: 0.2em;
        }
        label {
          color: $gray-xdark;
        }
      }
    }
    .registration-insurance__radio-title {
      padding-left: $reg-saver-left-spacing;
      font-weight: $font-semibold;
      font-size: $sn-font-size-lg;
      text-align: left;
      color: $black-color-insurance-header;
    }
    .registration-insurance__radio-label {
      font-size: $sn-font-size-sm;
      span {
        font-weight: $font-regular;
      }
    }
    .registration-insurance__banner-title {
      font-size: $sn-font-size-sm;
      font-weight: $font-semibold;
      color: $red-color-insurance-header;
      display: none;
    }
    .registration-insurance__banner-input {
      padding-top: $button-padding-vertical;
    }
    .registration-insurance__banner-link {
      color: $blue-color-variant-two !important;
      font-weight: $font-regular;
      text-decoration: underline;
    }
    .registration-insurance__partnership-icon {
      width: 282px;
      padding-top: 2rem;
      padding-bottom: 1rem;
    }
    .registration-insurance__reimbursed-container {
      background: $red-color-insurance-header;
      max-width: 278px;
    }
    .registration-insurance__reimbursed-title {
      font-size: $sn-font-size-lg;
      font-weight: $font-semibold;
      color: $account-nav-color;
      padding: $button-padding-vertical-small $button-padding-horizontal;
    }
    .registration-insurance__choice-text{
      font-size: $sn-font-size-sm;
      line-height: 1.67;
      color: $account-nav-background;
      font-weight: $font-regular;
      padding-top: $button-padding-horizontal;
      padding-bottom: $button-padding-horizontal;
    }
    .registration-insurance__radio-not-selected-error {
      display: none;
    }
    .registration-insurance__partnership-main-icon {
      float: right;
      height: 85px;
      position: relative;
      right: 0;
      top: -5px;
      width: 100px;
    }
    .registration-insurance__note {
      background-color: $reg-saver-insurance-note-backround;
      color: white;
      font-size: $font-xsmall;
      margin-bottom: $sub-nav-spacing;
      margin-left: $reg-saver-left-spacing;
      padding-left: $button-padding-horizontal;
    }
    .registration-insurance__partnership {
      padding-top: $button-padding-vertical;
    }
    .registration-insurance__partnership-go {
      font-size: $font-xsmall;
      font-style: italic;
      font-weight: $font-regular;
      padding-top: $button-padding-horizontal;
    }
  }
  &__footer {
    .pl-media--centered {
      padding: $layout-spacing-medium * 2 $layout-spacing-medium $layout-spacing-medium $layout-spacing-medium;
      .pl-media__image {
        .reg-saver-icon {
          width: 246px;
          height: 44px;
        }
      }
    }
  }
}

.registration-insurance.grid {
  margin: 1em 0;
  border: 1px solid $order-insurance-border-color;
}

html, body {
  font-size: .875em;
}

.registration-insurance.grid .registration-insurance__header {
  padding-top: 2px;
}

#survey_steps .siteContainer .insurance-checkbox {
  color: $gray-xdark;
  margin-top: 0;
  position: absolute;
}

.insurance-badge {
  border: 1px solid $border-variant-one-color;
  background: $component-variant-one-color;
  .insurance-badge__header {
    background-color: $black-color-variant-one;
    color: $site-search-submit-color;
    padding: 4px 8px;
    text-transform: uppercase;
  }
  .insurance-badge__content .body {
    padding: 1em;
  }
  .purchase {
    border: 1px solid $border-variant-one-color;
    padding: .5em;
    text-align: center;
    max-width: 150px;
    box-shadow: inset 0 -1px 0 0 rgba(35, 40, 43, 0.12);
    .purchase__title i {
      color: $green-color-variant-one;
    }

    .description {
      p {
        padding: 0;
        margin: 0 0 0.1em;
      }
      .cover.title {
        padding-bottom: .3em;
      }
    }
    .policy {
      &__number {
        font-size: 1.2em;
      }
      &__info {
        padding-bottom: 0;
        margin-bottom: 0.3em;
      }
    }
    .purchase__price {
      padding-bottom: .1em;
      font-weight: bold;
      font-size: 2em;
    }
  }
  .contact {
    padding: 1em 0;
  }
  .footer-section div a {
    color: $blue-color-variant-one !important;
    margin-right: 1em;
  }

  .badge {
    padding: 0 1em 1em 0;
  }
}

@media only screen and (max-width: $desktop-width) {
  .insurance-badge {
    .footer-section {
      div a {
        margin-right: 0;
        &:after {
          content: "";
          display: block;
          height: 0;
          width: 1px;
          padding-bottom: $layout-spacing-small;
        }
      }
    }
  }
}

